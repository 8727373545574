import { mdiLock } from '@mdi/js'
import Icon from '@mdi/react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useRecoilValue } from 'recoil'
import { useSetCart } from '../../atoms/cartAtom'
import userAtom from '../../atoms/userAtom'
import useCartInfo from '../../hooks/useCartInfo'
import useMyCard from '../../hooks/useMyCard'
import Campaign from '../../types/Campaign'
import { functions } from '../../utils/firebase'
import AuthScreen from '../Auth/AuthScreen'
import { PaymentMethodForm } from '../Portal/Billing'
import Toggle from '../Toggle'

export default () => {
  const user = useRecoilValue(userAtom)
  const [anon, setAnon] = useState<boolean>()
  const [formOpen, setFormOpen] = useState(false)
  const { cart, campaigns, cartTotal } = useCartInfo()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const setCart = useSetCart()
  const card = useMyCard()

  useEffect(() => {
    if (user && anon === undefined && user.anonymous !== undefined) {
      setAnon(user.anonymous)
    }
  }, [user, anon, setAnon])

  if (!user) {
    return <AuthScreen />
  }

  return (
    <div className='my-4 container'>
      <div className='text-colored text-4xl font-bold'>Checkout</div>
      <div className='grid mt-5 grid-cols-12 gap-5'>
        <div className='col-span-12 md:col-span-6'>
          <div className='flex justify-between items-center'>
            <div className='text-xl'>Payment Method</div>
            <div className='flex items-center'>
              <Icon path={mdiLock} color='#aaa' size={0.5} />
              <div className='text-sm ml-1'>Secure Payment</div>
            </div>
          </div>
          <div className='mt-3 bg-white p-4 rounded shadow'>
            <PaymentMethodForm />
          </div>
          <div className='flex justify-between items-center mt-3'>
            <Toggle
              text='Make my purchase anonymous'
              checked={!!anon}
              onClick={() => {
                setAnon(!anon)
              }}
            />
            <button
              className='button button-yellow'
              disabled={!card || loading}
              onClick={async () => {
                if (cart.length === 0) {
                  return
                }

                console.log(cart)

                setLoading(true)
                try {
                  const checkout = functions.httpsCallable('checkout')
                  await checkout({ cart })
                  localStorage.setItem(
                    'last-checkout',
                    JSON.stringify(cart)
                  )
                  setCart([])
                  history.push('/checkout-complete')
                } catch (e: any) {
                  alert(e.message)
                } finally {
                  setLoading(false)
                }
              }}
            >
              {loading ? 'Please wait...' : 'Complete Purchase'}
            </button>
          </div>
        </div>
        <div className='col-span-12 md:col-span-6'>
          <div className='flex justify-between items-center'>
            <div className='text-xl'>Order Summary</div>
            <div className='text-xl font-bold'>${cartTotal}</div>
          </div>
          {cart.map(item => {
            const campaign = campaigns[item.campaignId] as
              | Campaign
              | undefined
            return (
              <div className='mt-3 bg-white rounded shadow p-3'>
                <div className='font-bold'>{campaign?.title}</div>
                <div className='mt-3 flex justify-between items-center'>
                  <div className='flex items-center'>
                    Purchasing:
                    <div className='text-primary ml-2 font-bold'>
                      #{item.number}
                    </div>
                  </div>
                  <div className='text-lg'>
                    ${item.shares.length * (campaign?.pricePerShare || 0)}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
