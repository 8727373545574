import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import viewerAtom from '../atoms/viewerAtom'
import { BankfulCardToken } from '../types/BankfulTypes'
import { firestore } from '../utils/firebase'

export default () => {
  const [card, setCard] = useState<BankfulCardToken>()
  const viewer = useRecoilValue(viewerAtom)

  useEffect(() => {
    if (viewer) {
      const unsub = firestore
        .doc(`cards/${viewer.uid}`)
        .onSnapshot(snap => {
          setCard(snap.data() as BankfulCardToken | undefined)
        })
      return () => unsub()
    } else {
      setCard(undefined)
    }
  }, [viewer])

  return card
}
