import { map } from 'lodash'
import { useState } from 'react'
import { BankfulCreateCardTokenRequest } from '../types/BankfulTypes'
import { auth, functions } from '../utils/firebase'
import TextInput from './TextInput'

export default ({ dismiss }: { dismiss: () => void }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [expirationMonth, setExpirationMonth] = useState('')
  const [expirationYear, setExpirationYear] = useState('')
  const [cvc, setCvc] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [saving, setSaving] = useState(false)

  const clear = () => {
    setFirstName('')
    setLastName('')
    setCardNumber('')
    setExpirationMonth('')
    setExpirationYear('')
    setCvc('')
    setZip('')
    setAddress1('')
    setAddress2('')
    setCity('')
    setState('')
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        const user = auth.currentUser
        if (!user) {
          return
        }

        const body: BankfulCreateCardTokenRequest = {
          card_details: {
            card_cvv: cvc,
            card_exp_mm: expirationMonth,
            card_exp_yy: expirationYear,
            card_number: cardNumber
          },
          customer_details: {
            address_1: address1,
            city: city,
            email: user.email || 'noemail@gmail.com',
            first_name: firstName,
            last_name: lastName,
            state: state,
            zip: zip,
            address_2: address2
          }
        }

        setSaving(true)
        functions
          .httpsCallable('addCard')(body)
          .then(() => {
            clear()
            dismiss()
          })
          .catch(e => {
            alert(e.message)
          })
          .finally(() => {
            setSaving(false)
          })
      }}
    >
      <div className='text-xl'>New Payment Method</div>
      <div className='mt-3 grid grid-cols-12 gap-4'>
        <TextInput
          required
          label='Credit Card Number'
          className='col-span-12'
          value={cardNumber}
          onTextChange={setCardNumber}
          autoComplete='cc-number'
        />
        <TextInput
          required
          label='Exp. Month (MM)'
          autoComplete='cc-exp-month'
          className='col-span-4'
          value={expirationMonth}
          onTextChange={setExpirationMonth}
        />
        <TextInput
          required
          label='Exp. Year (YY)'
          autoComplete='cc-exp-year'
          className='col-span-4'
          value={expirationYear}
          onTextChange={setExpirationYear}
        />
        <TextInput
          required
          label='CVC'
          className='col-span-4'
          value={cvc}
          onTextChange={setCvc}
          autoComplete='cc-csc'
        />
        <TextInput
          required
          label='First Name'
          className='col-span-12 md:col-span-6'
          value={firstName}
          onTextChange={setFirstName}
          autoComplete='given-name'
        />
        <TextInput
          required
          label='Last Name'
          className='col-span-12 md:col-span-6'
          value={lastName}
          onTextChange={setLastName}
          autoComplete='family-name'
        />
        <TextInput
          required
          label='Address'
          autoComplete='address-line1'
          className='col-span-12'
          value={address1}
          onTextChange={setAddress1}
        />
        <TextInput
          label='Address Line 2 (Apt, Unit, Suite, etc.)'
          autoComplete='address-line2'
          className='col-span-12'
          value={address2}
          onTextChange={setAddress2}
        />
        <TextInput
          required
          label='City'
          className='col-span-12 md:col-span-6'
          value={city}
          onTextChange={setCity}
          autoComplete='address-level2'
        />
        <div className='col-span-12 md:col-span-6'>
          <div className='mb-1 text-xs text-gray-500'>
            State
            <span className='text-red-600'> *</span>
          </div>
          <select
            className='text-input'
            value={state}
            onChange={e => setState(e.target.value)}
          >
            {map(states, (value, key) => {
              return (
                <option value={key} key={key}>
                  {value}
                </option>
              )
            })}
          </select>
        </div>
        <TextInput
          required
          label='Zip Code'
          className='col-span-12 md:col-span-8'
          value={zip}
          onTextChange={setZip}
          autoComplete='postal-code'
        />
      </div>
      <div className='mt-4 flex items-center justify-between'>
        <button
          className='button button-outline'
          type='button'
          onClick={() => {
            clear()
            dismiss()
          }}
        >
          Cancel
        </button>
        <button
          disabled={saving}
          className='button button-yellow'
          type='submit'
        >
          {saving ? 'Saving...' : 'Save'}
        </button>
      </div>
    </form>
  )
}

const states = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
}
