import { useState } from 'react'
import { Helmet } from 'react-helmet'
import useMyCard from '../../hooks/useMyCard'
import createTitle from '../../utils/createTitle'
import { functions } from '../../utils/firebase'
import NewCardForm from '../NewCardForm'

export default () => {
  return (
    <div>
      <Helmet>
        <title>{createTitle('Billing')}</title>
      </Helmet>
      <div className='text-2xl'>Billing & Payment</div>
      <div className='mt-4 p-4 bg-white rounded shadow'>
        <PaymentMethodForm />
      </div>
    </div>
  )
}

export const PaymentMethodForm = () => {
  const [open, setOpen] = useState(false)
  const card = useMyCard()
  const [deleting, setDeleting] = useState(false)

  return (
    <div>
      {open ? (
        <NewCardForm dismiss={() => setOpen(false)} />
      ) : (
        <div>
          {card ? (
            <div>
              <div className='text-sm mt-2'>
                Card ending in {card.cc_last_4_digit}
              </div>
              <button
                className='mt-2 button button-yellow'
                disabled={deleting}
                onClick={() => {
                  if (window.confirm('Delete this card?')) {
                    setDeleting(true)
                    functions
                      .httpsCallable('deleteCard')()
                      .then(() => {
                        alert('Card successfully deleted!')
                      })
                      .catch(e => {
                        alert(e.message)
                      })
                      .finally(() => {
                        setDeleting(false)
                      })
                  }
                }}
              >
                {deleting ? 'Deleting...' : 'Delete Card'}
              </button>
            </div>
          ) : (
            <div>
              <p>You don't have a payment method added yet.</p>
              <button
                className='mt-2 button button-outline'
                onClick={() => {
                  setOpen(true)
                }}
              >
                New Card
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
